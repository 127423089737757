import { useEffect, useState } from 'react';
import {
	onSnapshot,
	collection,
	query,
	where,
	doc as firestoreDoc,
	updateDoc,
} from 'firebase/firestore';
import { taskService } from '../services/taskService';
import { userService } from '../services/userService';
import TaskItem from './TaskItem';
import { useAuth } from '../contexts/AuthContext';
import { DragDropContext } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faListCheck,
	faSpinner,
	faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';
import { db } from '../services/firebase';

export default function TaskList({ teamId, team }) {
	const [tasks, setTasks] = useState([]);
	const [columns, setColumns] = useState(team?.columns || []);
	const [showNewTaskModal, setShowNewTaskModal] = useState(false);
	const [newTaskColumn, setNewTaskColumn] = useState(null);
	const [newTaskTitle, setNewTaskTitle] = useState('');
	const [newTaskNote, setNewTaskNote] = useState('');
	const [newTaskTags, setNewTaskTags] = useState('');
	const [showConfetti, setShowConfetti] = useState(false);
	const [showEmoji, setShowEmoji] = useState(false);
	const { user } = useAuth();
	const { width, height } = useWindowSize();
	const [filters, setFilters] = useState({
		tags: [],
		assignee: '',
		priority: '',
	});
	const [sortByPriority, setSortByPriority] = useState(false);

	useEffect(() => {
		if (team?.columns) {
			setColumns(team.columns);
		}
	}, [team]);

	useEffect(() => {
		if (!teamId) return;

		console.log('Setting up task listener for team:', teamId);
		const q = query(collection(db, 'tasks'), where('teamId', '==', teamId));

		const unsubscribe = onSnapshot(q, (snapshot) => {
			const taskList = snapshot.docs.map((doc) => {
				const data = doc.data();
				// Clean up tags if they're invalid
				if (
					data.tags &&
					data.tags.length === 1 &&
					data.tags[0] === '#'
				) {
					const taskRef = firestoreDoc(db, 'tasks', doc.id);
					updateDoc(taskRef, { tags: [] });
					data.tags = [];
				}
				return {
					id: doc.id,
					...data,
					order: data.order || 0, // Add default order
				};
			});

			// Sort tasks by order within each category
			const sortedTasks = taskList.sort((a, b) => b.order - a.order);
			console.log('Fetched and sorted team tasks:', sortedTasks);
			setTasks(sortedTasks);
		});

		return () => unsubscribe();
	}, [teamId]);

	const handleAddTask = async (e) => {
		e.preventDefault();
		if (!newTaskTitle.trim()) return;

		try {
			// Get current highest order in the category
			const categoryTasks = tasks.filter(
				(task) => task.category === newTaskColumn
			);
			const highestOrder = Math.max(
				...categoryTasks.map((task) => task.order || 0),
				-1
			);

			// Create new task with order = highest + 1
			await taskService.createTask({
				title: newTaskTitle.trim(),
				note: newTaskNote.trim(),
				tags: [],
				assignedTo: '',
				createdBy: user.uid,
				teamId: teamId,
				category: newTaskColumn,
				order: -1 * (highestOrder + 1), // This puts it at the top since we sort by descending order
			});

			setShowNewTaskModal(false);
			setNewTaskTitle('');
			setNewTaskNote('');
			setNewTaskTags('');
			setNewTaskColumn(null);
		} catch (error) {
			console.error('Error creating task:', error);
		}
	};

	const handleColumnNameChange = async (columnId, newName) => {
		const newColumns = columns.map((col) =>
			col.id === columnId ? { ...col, name: newName } : col
		);
		setColumns(newColumns);
		try {
			await userService.updateTeamColumns(teamId, newColumns);
		} catch (error) {
			console.error('Error updating column name:', error);
		}
	};

	const handleDragEnd = async (result) => {
		const { source, destination, draggableId } = result;

		if (!destination) return;

		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		try {
			// Get current tasks in the source column
			const columnTasks = tasks.filter(
				(task) => task.category === source.droppableId
			);

			// Create new array of tasks
			const newTasks = Array.from(tasks);
			const [movedTask] = newTasks.splice(
				newTasks.findIndex((t) => t.id === draggableId),
				1
			);

			// Update task with new position
			const updatedTask = {
				...movedTask,
				category: destination.droppableId,
			};

			// Insert task at new position
			newTasks.splice(destination.index, 0, updatedTask);

			// Update UI immediately
			setTasks(newTasks);

			// Update in Firestore
			const taskRef = firestoreDoc(db, 'tasks', draggableId);
			await updateDoc(taskRef, {
				category: destination.droppableId,
			});

			// Keep existing column change animations
			if (source.droppableId !== destination.droppableId) {
				if (destination.droppableId === 'done') {
					setShowConfetti(true);
					setTimeout(() => setShowConfetti(false), 3000);
				}
				if (destination.droppableId === 'inprogress') {
					setShowEmoji(true);
					setTimeout(() => setShowEmoji(false), 2000);
				}
			}
		} catch (error) {
			console.error('Error updating task:', error);
		}
	};

	const getColumnHeaderColor = (columnId) => {
		switch (columnId) {
			case 'todo':
				return 'bg-blue-500 rounded-t-lg';
			case 'inprogress':
				return 'bg-yellow-500 rounded-t-lg';
			case 'done':
				return 'bg-green-500 rounded-t-lg';
			default:
				return 'bg-gray-500';
		}
	};

	const getColumnTitle = (columnId) => {
		switch (columnId) {
			case 'todo':
				return (
					<>
						<FontAwesomeIcon
							icon={faListCheck}
							className='mr-2'
						/>{' '}
						To Do
					</>
				);
			case 'inprogress':
				return (
					<>
						<FontAwesomeIcon
							icon={faSpinner}
							className='mr-2'
						/>{' '}
						In Progress
					</>
				);
			case 'done':
				return (
					<>
						<FontAwesomeIcon
							icon={faCheckDouble}
							className='mr-2'
						/>{' '}
						Done
					</>
				);
			default:
				return columnId;
		}
	};

	const getPriorityOrder = (priority) => {
		switch (priority) {
			case 'high':
				return 3;
			case 'medium':
				return 2;
			case 'low':
				return 1;
			default:
				return 0;
		}
	};

	const getFilteredTasks = (tasks, column) => {
		let filteredTasks = tasks
			.filter((task) => task.category === column.id)
			.filter((task) => {
				// Filter by tags
				if (filters.tags.length > 0) {
					const taskTags = task.tags || [];
					return filters.tags.some((tag) => taskTags.includes(tag));
				}
				return true;
			})
			.filter((task) => {
				// Filter by assignee
				if (filters.assignee) {
					return task.assignedTo === filters.assignee;
				}
				return true;
			});

		// Add priority sorting
		if (sortByPriority) {
			filteredTasks = filteredTasks.sort(
				(a, b) =>
					getPriorityOrder(b.priority) - getPriorityOrder(a.priority)
			);
		}

		return filteredTasks;
	};

	const allTags = [...new Set(tasks.flatMap((task) => task.tags || []))];
	const allAssignees = [
		...new Set(tasks.map((task) => task.assignedTo).filter(Boolean)),
	];
	const priorities = ['high', 'medium', 'low'];

	if (!team) {
		return <div>Loading board...</div>;
	}

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			{showConfetti && (
				<Confetti
					width={width}
					height={height}
					recycle={false}
					numberOfPieces={200}
					gravity={0.3}
				/>
			)}

			{showEmoji && (
				<div
					className='fixed top-1/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-6xl animate-ping z-20'
					style={{ pointerEvents: 'none' }}
				>
					💪
				</div>
			)}

			<div className='max-w-6xl mx-auto py-2 mb-4'>
				<div className='flex gap-2 items-center justify-end'>
					{/* Tags dropdown - updated */}
					<select
						value={filters.tags}
						onChange={(e) =>
							setFilters((prev) => ({
								...prev,
								tags: [e.target.value], // Changed to single selection
							}))
						}
						className='px-2 py-1 text-xs border border-gray-300 rounded-md'
					>
						<option value=''>All tags</option>
						{allTags.map((tag) => (
							<option
								key={tag}
								value={tag}
							>
								{tag}
							</option>
						))}
					</select>

					{/* Assignee dropdown */}
					<select
						value={filters.assignee}
						onChange={(e) =>
							setFilters((prev) => ({
								...prev,
								assignee: e.target.value,
							}))
						}
						className='px-2 py-1 text-xs border border-gray-300 rounded-md'
					>
						<option value=''>Assignee</option>
						{allAssignees.map((assignee) => (
							<option
								key={assignee}
								value={assignee}
							>
								{assignee}
							</option>
						))}
					</select>

					{/* Priority dropdown */}
					<select
						value={filters.priority}
						onChange={(e) =>
							setFilters((prev) => ({
								...prev,
								priority: e.target.value,
							}))
						}
						className='px-2 py-1 text-xs border border-gray-300 rounded-md'
					>
						<option value=''>Priority</option>
						{priorities.map((priority) => (
							<option
								key={priority}
								value={priority}
							>
								{priority.charAt(0).toUpperCase() +
									priority.slice(1)}
							</option>
						))}
					</select>

					<button
						onClick={() => setSortByPriority(!sortByPriority)}
						className={`px-3 py-1 text-xs rounded-md transition-colors ${
							sortByPriority
								? 'bg-blue-100 text-blue-800 hover:bg-blue-200'
								: 'border border-gray-300 text-gray-600 hover:bg-gray-50'
						}`}
					>
						{sortByPriority
							? 'Cancel Priority Sort'
							: 'Sort by Priority'}
					</button>

					{/* Clear filters button */}
					{(filters.tags.length > 0 ||
						filters.assignee ||
						filters.priority) && (
						<button
							onClick={() =>
								setFilters({
									tags: [],
									assignee: '',
									priority: '',
								})
							}
							className='px-2 py-1 text-xs text-gray-600 hover:text-gray-900'
						>
							Clear
						</button>
					)}
				</div>
			</div>

			<div className='h-[calc(95vh-13rem)] overflow-hidden'>
				<div className='flex h-full gap-4 max-w-6xl mx-auto'>
					{columns.map((column) => (
						<div
							key={column.id}
							className='flex-1 flex flex-col h-full rounded-lg border border-gray-300 bg-gray-50 bg-opacity-30'
						>
							<div
								className={`${getColumnHeaderColor(
									column.id
								)} px-4 py-3 flex justify-between items-center`}
							>
								<span className='text-sm text-white'>
									{getColumnTitle(column.id)}
								</span>
								<button
									onClick={() => {
										setNewTaskColumn(column.id);
										setShowNewTaskModal(true);
									}}
									className='text-white hover:text-gray-200 text-md'
								>
									+
								</button>
							</div>
							<div className='flex-1 overflow-y-auto p-3 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent hover:scrollbar-thumb-gray-300'>
								<StrictModeDroppable droppableId={column.id}>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											className={`space-y-2 min-h-full ${
												snapshot.isDraggingOver
													? 'bg-blue-50 bg-opacity-50'
													: ''
											}`}
										>
											{getFilteredTasks(
												tasks,
												column
											).map((task, index) => (
												<TaskItem
													key={task.id}
													task={task}
													index={index}
													projectId={teamId}
												/>
											))}
											{provided.placeholder}
										</div>
									)}
								</StrictModeDroppable>
							</div>
						</div>
					))}
				</div>
			</div>

			{/* New Task Modal */}
			{showNewTaskModal && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-xl shadow-xl p-6 w-full max-w-md m-4'>
						<div className='flex justify-between items-center mb-6'>
							<h3 className='text-xl font-semibold text-gray-900'>
								Create New Task
							</h3>
							<button
								onClick={() => {
									setShowNewTaskModal(false);
									setNewTaskTitle('');
									setNewTaskNote('');
									setNewTaskTags('');
								}}
								className='text-gray-400 hover:text-gray-500 focus:outline-none'
							>
								<svg
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							</button>
						</div>
						<form
							onSubmit={handleAddTask}
							className='space-y-4'
						>
							<div>
								<label
									htmlFor='title'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Task Title
								</label>
								<input
									id='title'
									type='text'
									value={newTaskTitle}
									onChange={(e) =>
										setNewTaskTitle(e.target.value)
									}
									placeholder='Enter task title'
									className='w-full px-4 py-2.5 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
									required
								/>
							</div>
							<div>
								<label
									htmlFor='tags'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Tags
								</label>
								<input
									id='tags'
									type='text'
									value={newTaskTags}
									onChange={(e) =>
										setNewTaskTags(e.target.value)
									}
									placeholder='Add tags (e.g., #frontend #urgent)'
									className='w-full px-4 py-2.5 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
								<p className='mt-1 text-xs text-gray-500'>
									Separate tags with spaces. # will be added
									automatically if missing.
								</p>
							</div>
							<div>
								<label
									htmlFor='note'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Note (optional)
								</label>
								<textarea
									id='note'
									value={newTaskNote}
									onChange={(e) =>
										setNewTaskNote(e.target.value)
									}
									placeholder='Add a note...'
									rows='3'
									className='w-full px-4 py-2.5 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
							</div>
							<div className='flex justify-end space-x-3 mt-6'>
								<button
									type='button'
									onClick={() => {
										setShowNewTaskModal(false);
										setNewTaskTitle('');
										setNewTaskNote('');
										setNewTaskTags('');
									}}
									className='px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
								>
									Cancel
								</button>
								<button
									type='submit'
									className='px-4 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
								>
									Create Task
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
		</DragDropContext>
	);
}
