import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { collection, query, where, getDocs, getDoc, doc as firestoreDoc } from 'firebase/firestore';
import { db } from '../services/firebase';

export default function Settings() {
  const { userProfile, refreshUserProfile } = useAuth();
  const [username, setUsername] = useState(userProfile?.username || '');
  const [teams, setTeams] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Load teams
  useState(() => {
    const loadTeams = async () => {
      if (userProfile) {
        const userTeams = await userService.getUserTeams(userProfile.id);
        setTeams(userTeams);
      }
    };
    loadTeams();
  }, [userProfile]);

  const handleUpdateUsername = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      await userService.updateUserProfile(userProfile.id, {
        ...userProfile,
        username: username.trim()
      });
      await refreshUserProfile(userProfile.id);
      setSuccess('Username updated successfully');
      setIsEditing(false);
    } catch (error) {
      setError('Failed to update username');
      console.error('Error updating username:', error);
    }
  };

  const handleDeleteTeam = async (teamId) => {
    if (!window.confirm('Are you sure you want to delete this team? This action cannot be undone.')) {
      return;
    }

    try {
      await userService.deleteTeam(teamId);
      setTeams(teams.filter(team => team.id !== teamId));
      setSuccess('Team deleted successfully');
    } catch (error) {
      setError('Failed to delete team');
      console.error('Error deleting team:', error);
    }
  };

  const handleLeaveTeam = async (teamId) => {
    if (!window.confirm('Are you sure you want to leave this team?')) {
      return;
    }

    try {
      await userService.removeTeamMember(teamId, userProfile.id);
      setTeams(teams.filter(team => team.id !== teamId));
      setSuccess('Left team successfully');
    } catch (error) {
      setError('Failed to leave team');
      console.error('Error leaving team:', error);
    }
  };

  const renderTeamMembers = (teamId) => {
    const team = teams.find(t => t.id === teamId);
    if (!team?.members) return null;

    // Get all team members for this team
    const teamMembersRef = collection(db, 'teamMembers');
    const q = query(teamMembersRef, where('teamId', '==', teamId));
    
    getDocs(q).then(async (snapshot) => {
      const memberPromises = snapshot.docs.map(async (doc) => {
        const userId = doc.data().userId;
        const userRef = firestoreDoc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          return {
            id: userDoc.id,
            ...userDoc.data(),
            role: doc.data().role
          };
        }
        return null;
      });

      const members = (await Promise.all(memberPromises)).filter(member => member !== null);
      team.members = members;
    });

    return (
      <div className="mt-4 border-t border-gray-200 pt-4">
        <h4 className="text-sm font-medium text-gray-700 mb-2">Team Members</h4>
        <div className="space-y-2">
          {team.members.map(member => (
            <div key={member.id} className="flex items-center justify-between bg-gray-50 p-2 rounded">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center">
                  <FontAwesomeIcon icon={faUser} className="text-teal-600" />
                </div>
                <div>
                  <div className="text-sm font-medium">
                    {member.username || member.email}
                  </div>
                  <div className="text-xs text-gray-500">{member.email}</div>
                </div>
              </div>
              <span className={`text-xs px-2 py-1 rounded-full ${
                member.role === 'owner' 
                  ? 'bg-teal-100 text-teal-800' 
                  : 'bg-gray-200 text-gray-800'
              }`}>
                {member.role === 'owner' ? 'Owner' : 'Member'}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTeam = (team) => (
    <div key={team.id} className="bg-white/70 backdrop-blur-lg rounded-xl border border-white/20 p-6 mb-4 shadow-lg">
      <h3 className="text-lg font-semibold text-gray-900">{team.name}</h3>
      {renderTeamMembers(team.id)}
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-900">Settings</h2>
      <div className="space-y-6">
        <div className="space-y-4">
          {teams.map(renderTeam)}
        </div>
      </div>
    </div>
  );
} 