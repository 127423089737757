import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/userService';
import TaskList from './TaskList';
import { useNavigate } from 'react-router-dom';

export default function TeamBoard() {
	const [teams, setTeams] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [showInvite, setShowInvite] = useState(false);
	const [inviteEmail, setInviteEmail] = useState('');
	const [inviteMessage, setInviteMessage] = useState('');
	const { userProfile } = useAuth();
	const [showNewTeamModal, setShowNewTeamModal] = useState(false);
	const [newTeamName, setNewTeamName] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		const loadTeams = async () => {
			if (userProfile) {
				const userTeams = await userService.getUserTeams(
					userProfile.id
				);
				console.log('Loaded teams:', userTeams);
				setTeams(userTeams);
				if (userTeams.length > 0 && !selectedTeam) {
					console.log('Setting initial selected team:', userTeams[0]);
					setSelectedTeam(userTeams[0]);
				}
			}
		};
		loadTeams();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userProfile]);

	const handleInviteMember = async (e) => {
		e.preventDefault();
		setInviteMessage('');
		try {
			await userService.addTeamMember(selectedTeam.id, inviteEmail);
			setInviteMessage(
				'Invitation sent! If the user already has an account, they will be added to the team immediately. ' +
					'Otherwise, they will be added when they sign up.'
			);
			setTimeout(() => {
				setShowInvite(false);
				setInviteEmail('');
				setInviteMessage('');
			}, 3000);
		} catch (error) {
			console.error('Error inviting member:', error);
			setInviteMessage('Failed to send invitation. Please try again.');
		}
	};

	const handleCreateTeam = async (e) => {
		e.preventDefault();
		if (!newTeamName.trim()) return;

		try {
			const newTeam = await userService.createTeam(
				newTeamName.trim(),
				userProfile.id
			);
			setTeams([...teams, newTeam]);
			setSelectedTeam(newTeam);
			setNewTeamName('');
			setShowNewTeamModal(false);
			navigate(`/?teamId=${newTeam.id}`);
		} catch (error) {
			console.error('Error creating team:', error);
		}
	};

	return (
		<div className='py-4 mx-[5%]'>
			<div className='max-w-6xl mx-auto flex items-end justify-end py-4'>
				<div className='flex items-center gap-4'>
					<button
						onClick={() => setShowNewTeamModal(true)}
						className='bg-teal-600 text-white w-8 h-8 rounded-full flex items-center justify-center text-xl font-semibold hover:bg-teal-700 transition-colors shadow-md hover:shadow-lg'
						title='Create new team'
					>
						+
					</button>

					<div className='flex items-center gap-4'>
						<select
							value={selectedTeam?.id || ''}
							onChange={(e) => {
								const team = teams.find(
									(t) => t.id === e.target.value
								);
								setSelectedTeam(team);
							}}
							className='border border-gray-700 rounded-md px-3 py-2 focus:outline-none focus:border-gray-700 focus:ring-0'
						>
							{teams.map((team) => (
								<option
									key={team.id}
									value={team.id}
								>
									{team.name}
								</option>
							))}
						</select>

						<button
							onClick={() => setShowInvite(true)}
							className='bg-teal-600 text-white px-4 py-2 rounded-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2'
						>
							Invite Team Member
						</button>
					</div>
				</div>
			</div>

			{/* New Team Modal */}
			{showNewTeamModal && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-lg p-6 w-full max-w-md mx-4'>
						<div className='flex justify-between items-center mb-4'>
							<h3 className='text-lg font-semibold'>
								Create New Team
							</h3>
							<button
								onClick={() => setShowNewTeamModal(false)}
								className='text-gray-400 hover:text-gray-500'
							>
								×
							</button>
						</div>
						<form onSubmit={handleCreateTeam}>
							<input
								type='text'
								value={newTeamName}
								onChange={(e) => setNewTeamName(e.target.value)}
								placeholder='Team name'
								className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500'
								autoFocus
							/>
							<div className='flex justify-end mt-4 space-x-2'>
								<button
									type='button'
									onClick={() => setShowNewTeamModal(false)}
									className='px-4 py-2 text-gray-600 hover:text-gray-800'
								>
									Cancel
								</button>
								<button
									type='submit'
									className='px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700'
								>
									Create Team
								</button>
							</div>
						</form>
					</div>
				</div>
			)}

			{/* Invite Member Modal */}
			{showInvite && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-xl shadow-xl p-6 w-full max-w-md m-4'>
						<div className='flex justify-between items-center mb-6'>
							<h3 className='text-xl font-semibold text-gray-900'>
								Invite Team Member
							</h3>
							<button
								onClick={() => {
									setShowInvite(false);
									setInviteMessage('');
								}}
								className='text-gray-400 hover:text-gray-500 focus:outline-none'
							>
								<svg
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							</button>
						</div>
						{inviteMessage && (
							<div
								className={`mb-4 p-3 rounded-lg text-sm ${
									inviteMessage.includes('Failed')
										? 'bg-red-50 text-red-600 border border-red-200'
										: 'bg-green-50 text-green-600 border border-green-200'
								}`}
							>
								{inviteMessage}
							</div>
						)}
						<form
							onSubmit={handleInviteMember}
							className='space-y-4'
						>
							<div>
								<label
									htmlFor='email'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Email Address
								</label>
								<input
									id='email'
									type='email'
									value={inviteEmail}
									onChange={(e) =>
										setInviteEmail(e.target.value)
									}
									placeholder='colleague@example.com'
									className='w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
							</div>
							<div className='flex justify-end space-x-3 mt-6'>
								<button
									type='button'
									onClick={() => {
										setShowInvite(false);
										setInviteMessage('');
									}}
									className='px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
								>
									Cancel
								</button>
								<button
									type='submit'
									className='px-4 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
								>
									Send Invitation
								</button>
							</div>
						</form>
					</div>
				</div>
			)}

			{selectedTeam && (
				<TaskList
					teamId={selectedTeam.id}
					team={selectedTeam}
				/>
			)}
		</div>
	);
}
