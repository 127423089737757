import { useState, useEffect, useRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { taskService } from '../services/taskService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faReply, faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useAuth } from '../contexts/AuthContext';

const PRIORITIES = {
  high: { label: 'High', color: 'bg-red-100 text-red-800' },
  medium: { label: 'Medium', color: 'bg-yellow-100 text-yellow-800' },
  low: { label: 'Low', color: 'bg-green-100 text-green-800' }
};

export default function TaskItem({ task, index, projectId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isAssigning, setIsAssigning] = useState(false);
  const [note, setNote] = useState(task.note || '');
  const [users, setUsers] = useState([]);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [noteReplies, setNoteReplies] = useState([]);
  const [newReply, setNewReply] = useState('');
  const { userProfile } = useAuth();
  const dropdownRef = useRef(null);
  const [noteAuthor, setNoteAuthor] = useState('');
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [editingTagIndex, setEditingTagIndex] = useState(null);
  const [editingTagValue, setEditingTagValue] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(task.title);
  const [priority, setPriority] = useState(task.priority || null);
  const [showPriorityMenu, setShowPriorityMenu] = useState(false);

  useEffect(() => {
    console.log('Task data:', task);
    console.log('Checking task tags:', task.tags);
    
    if (!Object.prototype.hasOwnProperty.call(task, 'tags')) {
      console.log('Task has no tags property, initializing...');
      const taskRef = doc(db, 'tasks', task.id);
      updateDoc(taskRef, { tags: [] })
        .then(() => {
          console.log('Successfully initialized tags array for new task');
          setTags([]);
        })
        .catch(error => console.error('Error initializing tags:', error));
    } else {
      console.log('Task already has tags property:', task.tags);
      setTags(task.tags?.map(tag => tag.startsWith('#') ? tag.replace('#', '') : tag) || []);
    }
  }, [task.id, task.tags]);

  useEffect(() => {
    const fetchProjectUsers = async () => {
      if (!projectId) return;

      try {
        // Get all team members for this project/team
        const teamMembersRef = collection(db, 'teamMembers');
        const teamQuery = query(teamMembersRef, where('teamId', '==', projectId));
        const teamSnapshot = await getDocs(teamQuery);

        // Get user details for each team member
        const userPromises = teamSnapshot.docs.map(async (teamDoc) => {
          const userRef = doc(db, 'users', teamDoc.data().userId);
          const userSnap = await getDoc(userRef);
          
          if (userSnap.exists()) {
            return {
              id: userSnap.id,
              ...userSnap.data(),
              role: teamDoc.data().role
            };
          }
          return null;
        });

        const teamUsers = (await Promise.all(userPromises)).filter(user => user !== null);
        console.log('Team members loaded:', teamUsers);
        setUsers(teamUsers);
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

    fetchProjectUsers();

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsAssigning(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [projectId]);

  const handleAddReply = async () => {
    if (!newReply.trim() || !userProfile) return;

    try {
      const reply = {
        content: newReply,
        author: userProfile.username || userProfile.email,
        timestamp: new Date().toISOString()
      };

      const taskRef = doc(db, 'tasks', task.id);
      const taskDoc = await getDoc(taskRef);
      const currentReplies = taskDoc.exists() ? (taskDoc.data().replies || []) : [];
      const updatedReplies = [...currentReplies, reply];

      await updateDoc(taskRef, {
        replies: updatedReplies
      });
      
      setNoteReplies(updatedReplies);
      setNewReply('');
    } catch (error) {
      console.error('Error adding reply:', error);
    }
  };

  if (!task.id) {
    console.error('Task missing ID:', task);
    return null;
  }

  const handleSaveNote = async () => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        note,
        noteAuthor: userProfile?.username || userProfile?.email || 'Unknown'
      });
      setNoteAuthor(userProfile?.username || userProfile?.email || 'Unknown');
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving note:', error);
    }
  };

  const handleAssigneeChange = async (username) => {
    try {
      await taskService.updateTask(task.id, { assignedTo: username });
      setIsAssigning(false);
    } catch (error) {
      console.error('Error updating assignee:', error);
    }
  };

  const handleShowNoteModal = async () => {
    console.log('Showing note modal');
    try {
      // Get replies directly from the task document
      const taskRef = doc(db, 'tasks', task.id);
      const taskDoc = await getDoc(taskRef);
      
      if (taskDoc.exists()) {
        const taskData = taskDoc.data();
        setNote(taskData.note || '');
        setNoteReplies(taskData.replies || []);
        setNoteAuthor(taskData.noteAuthor || 'Unknown');
        setShowNoteModal(true);
      }
    } catch (error) {
      console.error('Error fetching note and replies:', error);
    }
  };

  const handleDeleteReply = async (replyIndex) => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      const taskDoc = await getDoc(taskRef);
      
      if (taskDoc.exists()) {
        // Get current replies from state instead of undefined variable
        const updatedReplies = noteReplies.filter((_, index) => index !== replyIndex);
        
        // First update UI for smooth animation
        const replyElement = document.querySelector(`[data-reply-index="${replyIndex}"]`);
        if (replyElement) {
          // Apply the Tailwind animation class
          replyElement.classList.add('animate-slideOut');
      
          // Wait for animation to finish
          await new Promise(resolve => setTimeout(resolve, 1000));
      
          // Now remove the element from the DOM
          replyElement.remove();
        }

        // Update Firestore
        await updateDoc(taskRef, {
          replies: updatedReplies
        });
        
        // Update local state
        setNoteReplies(updatedReplies);
      }
    } catch (error) {
      console.error('Error deleting reply:', error);
    }
  };

  const handleCloseNoteModal = () => {
    setShowNoteModal(false);
    setNewReply('');
  };

  const handleTagSubmit = async (e) => {
    e.preventDefault();
    if (!newTag.trim()) return;

    try {
      const updatedTags = [...tags, newTag.trim()];
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      setTags(updatedTags);
      setNewTag('');
    } catch (error) {
      console.error('Error updating tags:', error);
    }
  };

  const handleRemoveTag = async (tagToRemove) => {
    try {
      const updatedTags = tags.filter(tag => tag !== tagToRemove);
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      setTags(updatedTags);
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleTagEdit = async (index, newValue) => {
    if (!newValue.trim()) return;
    
    try {
      const updatedTags = [...tags];
      updatedTags[index] = newValue.trim();
      
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      
      setTags(updatedTags);
      setEditingTagIndex(null);
      setEditingTagValue('');
    } catch (error) {
      console.error('Error updating tag:', error);
    }
  };

  const handleTitleUpdate = async () => {
    if (!editedTitle.trim() || editedTitle === task.title) {
      setIsEditingTitle(false);
      setEditedTitle(task.title);
      return;
    }

    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        title: editedTitle.trim()
      });
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating task title:', error);
      setEditedTitle(task.title);
    }
  };

  const handleInitialTagAdd = async (tagValue) => {
    if (!tagValue.trim()) return;
    
    try {
      const taskRef = doc(db, 'tasks', task.id);
      const newTag = tagValue.trim();
      const updatedTags = [newTag];
      
      await updateDoc(taskRef, {
        tags: updatedTags.map(tag => tag.startsWith('#') ? tag : `#${tag}`)
      });
      
      setTags(updatedTags);
      setEditingTagIndex(null);
      setEditingTagValue('');
    } catch (error) {
      console.error('Error adding initial tag:', error);
    }
  };

  const handlePriorityChange = async (newPriority) => {
    try {
      const taskRef = doc(db, 'tasks', task.id);
      await updateDoc(taskRef, {
        priority: newPriority
      });
      setPriority(newPriority);
      setShowPriorityMenu(false);
    } catch (error) {
      console.error('Error updating priority:', error);
    }
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`border border-gray-100 p-2 rounded-lg bg-white hover:shadow-md bg-opacity-50 relative ${
              snapshot.isDragging ? 'bg-gray-100 backdrop-blur-sm shadow-lg' : ''
            }`}
          >
            <div className="flex justify-between items-start">
              {isEditingTitle ? (
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  onBlur={handleTitleUpdate}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleTitleUpdate();
                    }
                  }}
                  className="text-sm font-medium w-full px-1 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  autoFocus
                />
              ) : (
                <span 
                  className="text-sm font-medium cursor-pointer hover:text-gray-600"
                  onClick={() => {
                    setIsEditingTitle(true);
                    setEditedTitle(task.title);
                  }}
                >
                  {task.title}
                </span>
              )}
              <button
                onClick={() => taskService.deleteTask(task.id)}
                className="text-red-500 hover:text-red-700 text-xs ml-2"
              >
                ×
              </button>
            </div>
            
            {(!tags || tags.length === 0) && (
              <div
                className="text-xs text-gray-400 hover:text-gray-600 cursor-pointer mt-1"
                onClick={() => {
                  setEditingTagIndex(0);
                  setEditingTagValue('');
                } }
              >
                {editingTagIndex === 0 ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleInitialTagAdd(editingTagValue);
                    } }
                    className="flex items-center"
                  >
                    <input
                      type="text"
                      value={editingTagValue}
                      onChange={(e) => setEditingTagValue(e.target.value)}
                      className="text-xs border border-gray-300 rounded px-1 py-0.5 w-20 focus:outline-none focus:border-blue-500"
                      placeholder="Enter tag"
                      autoFocus
                      onBlur={() => {
                        if (editingTagValue.trim()) {
                          handleInitialTagAdd(editingTagValue);
                        } else {
                          setEditingTagIndex(null);
                          setEditingTagValue('');
                        }
                      } } />
                  </form>
                ) : (
                  'add a tag'
                )}
              </div>
            )}
            
            <div className="flex flex-wrap gap-1 mt-1">
              {tags.map((tag, index) => (
                <div key={index} className="flex items-center">
                  {editingTagIndex === index ? (
                    <form 
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleTagEdit(index, editingTagValue);
                      }}
                      className="flex items-center"
                    >
                      <input
                        type="text"
                        value={editingTagValue}
                        onChange={(e) => setEditingTagValue(e.target.value)}
                        className="text-xs border border-gray-300 rounded px-1 py-0.5 w-20 focus:outline-none focus:border-blue-500"
                        autoFocus
                        onBlur={() => {
                          setEditingTagIndex(null);
                          setEditingTagValue('');
                        }}
                      />
                    </form>
                  ) : (
                    <>
                      <span 
                        className="text-xs text-blue-600 hover:text-blue-800 cursor-pointer"
                        onClick={() => {
                          setEditingTagIndex(index);
                          setEditingTagValue(tag);
                        }}
                      >
                        {tag ? `#${tag}` : ''}
                      </span>
                      {tag && (
                      <button
                        onClick={() => handleRemoveTag(tag)}
                        className="ml-1 text-xs text-gray-400 hover:text-red-500"
                      >
                        ×
                      </button>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
            
            <div className="mt-1 relative" ref={dropdownRef}>
              <div
                onClick={() => setIsAssigning(!isAssigning)}
                className="text-xs text-gray-600 cursor-pointer hover:text-gray-900 inline-flex items-center gap-1 group"
              >
                {task.assignedTo ? `Assigned to: ${task.assignedTo}` : 'Unassigned'}
                <svg 
                  className={`w-3 h-3 transition-transform ${isAssigning ? 'rotate-180' : ''} opacity-50 group-hover:opacity-100`} 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              
              {isAssigning && (
                <div className="absolute left-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                  <div className="py-1">
                    <div
                      className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleAssigneeChange('')}
                    >
                      Unassign
                    </div>
                    <div
                      className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleAssigneeChange(userProfile?.username)}
                    >
                      Me ({userProfile?.username})
                    </div>
                    {users
                      .filter(user => user.username !== userProfile?.username)
                      .map(user => (
                        <div
                          key={user.id}
                          className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleAssigneeChange(user.username)}
                        >
                          {user.username}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>

            <div className="mt-1">
              {isEditing ? (
                <div className="space-y-1">
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    className="w-full p-1 border rounded text-xs focus:outline-none focus:border-gray-700 focus:ring-0"
                    rows="2"
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={handleSaveNote}
                      className="bg-blue-500 text-white px-2 py-1 rounded text-xs hover:bg-blue-600"
                    >
                      Save
                    </button>
                    <button
                      onClick={handleShowNoteModal}
                      className="bg-gray-500 text-white px-2 py-1 rounded text-xs hover:bg-gray-600"
                    >
                      Reply {task.replies?.length > 0 ? `(${task.replies?.length})` : ''}
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-500 text-white px-2 py-1 rounded text-xs hover:bg-gray-600"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setIsEditing(true)}
                  className="text-xs text-gray-600 cursor-pointer hover:text-gray-900"
                >
                  {task.note || 'Add a note...'}
                </div>
              )}
            </div>

            <div className="flex items-center mt-1 space-x-2">
              <div className="relative">
                <button
                  onClick={() => setShowPriorityMenu(!showPriorityMenu)}
                  className="text-xs text-gray-600 hover:text-gray-900"
                >
                  {priority ? (
                    <span className={`px-2 py-1 rounded-full text-xs ${PRIORITIES[priority].color}`}>
                      {PRIORITIES[priority].label}
                    </span>
                  ) : (
                    'Set priority'
                  )}
                </button>

                {showPriorityMenu && (
                  <div className="absolute left-0 mt-1 w-32 bg-white rounded-md shadow-lg z-10">
                    {Object.entries(PRIORITIES).map(([key, { label, color }]) => (
                      <button
                        key={key}
                        onClick={() => handlePriorityChange(key)}
                        className={`block w-full text-left px-4 py-2 text-xs hover:bg-gray-100 ${
                          priority === key ? 'font-bold' : ''
                        }`}
                      >
                        <span className={`px-2 py-1 rounded-full ${color}`}>
                          {label}
                        </span>
                      </button>
                    ))}
                    {priority && (
                      <button
                        onClick={() => handlePriorityChange(null)}
                        className="block w-full text-left px-4 py-2 text-xs text-gray-600 hover:bg-gray-100 border-t"
                      >
                        Clear priority
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            {task.replies?.length > 0 && (
              <div className="absolute bottom-2 right-2">
                <button
                  onClick={handleShowNoteModal}
                  className="text-blue-500 hover:text-blue-600 transition-colors"
                  title={`${task.replies.length} ${task.replies.length === 1 ? 'reply' : 'replies'}`}
                >
                  <FontAwesomeIcon icon={faCommentDots} className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>

          {/* Note Modal */}
          {showNoteModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-top justify-center z-50 pt-20 pb-6 overflow-y-scroll">
              <div className="bg-white backdrop-blur-lg rounded-lg w-full max-w-3xl mx-4 h-[70vh]">
                <div className="flex justify-between items-center mb-4 bg-blue-50 rounded-lg pl-6 pr-6 pt-4 pb-5 ">
                  <h3 className="text-lg font-semibold">Notes & Replies</h3>
                  <button 
                    onClick={handleCloseNoteModal}
                  >
                    ×
                  </button>
                </div>

                {/* Note Section */}
                <div className="mb-6 border-b px-8 py-4 border-gray-200">
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Note by {noteAuthor || 'Unknown'} on {new Date(task.createdAt).toLocaleString()}
                  </label>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    {task.updatedAt && (
                      <>
                        Note updated on {new Date(task.updatedAt).toLocaleString()}
                      </>
                    )}
                  </label>
                  <div className="text-s text-gray-700 mb-2">
                    {task.note}
                  </div>
                </div>

                {/* Replies Section */}
                <div className="px-8">
                  <h4 className="font-medium text-gray-900">Replies</h4>
                  <div className="space-y-4 max-h-[35vh] overflow-y-auto mb-4">
                    {noteReplies.map((reply, index) => (
                      <div key={index} className="bg-gray-50 rounded-lg p-3">
                        <div className="flex justify-between items-start">
                          <div className="flex items-center space-x-2 mb-2">
                            <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                              <FontAwesomeIcon icon={faUser} className="text-blue-500" />
                            </div>
                            <div>
                              <div className="text-sm font-medium">{reply.author}</div>
                              <div className="text-xs text-gray-500">
                                {new Date(reply.timestamp).toLocaleString()}
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => handleDeleteReply(index)}
                            className="text-red-500 hover:text-red-700 text-xs"
                          >
                            ×
                          </button>
                        </div>
                        <p className="text-sm text-gray-700">{reply.content}</p>
                      </div>
                    ))}
                  </div>

                  {/* New Reply Input */}
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      value={newReply}
                      onChange={(e) => setNewReply(e.target.value)}
                      placeholder="Write a reply..."
                      className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                    <button
                      onClick={handleAddReply}
                      className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600"
                    >
                      Reply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Draggable>
  );
} 