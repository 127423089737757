import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../services/firebase';

export default function OnboardingScreen() {
  const [username, setUsername] = useState('');
  const { user, userProfile, refreshUserProfile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const teamId = params.get('teamId');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username.trim()) return;

    try {
      console.log('Setting username:', username.trim());
      
      // Create the base user data object with only defined values
      const userData = {
        username: username.trim(),
        email: user.email,
        onboardingComplete: true,
        createdAt: serverTimestamp(),
      };

      // Only add team-related fields if teamId exists
      if (teamId) {
        userData.currentTeamId = teamId;
        userData.teams = [teamId];
      }

      // Update user profile
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, userData, { merge: true });

      // If there's a teamId, create team membership
      if (teamId) {
        console.log('Creating team membership for:', { userId: user.uid, teamId });
        
        // Create team member document with only defined values
        const teamMemberRef = doc(db, 'teamMembers', `${teamId}_${user.uid}`);
        await setDoc(teamMemberRef, {
          userId: user.uid,
          teamId: teamId,
          role: 'member',
          joinedAt: serverTimestamp(),
          active: true
        });
      }

      // Refresh user profile
      await refreshUserProfile(user.uid);

      // Navigate to appropriate page
      if (teamId) {
        navigate(`/?teamId=${teamId}`, { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } catch (error) {
      console.error('Error in onboarding:', error);
    }
  };

  if (!user) {
    console.log('No user, returning null');
    return null;
  }
  if (userProfile?.username && userProfile?.onboardingComplete) {
    console.log('User already onboarded, returning null');
    return null;
  }

  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="max-w-md w-full p-6">
        <h2 className="text-2xl font-bold mb-4">Welcome to Task Manager!</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Choose a username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-700 rounded-md focus:outline-none focus:border-gray-700 focus:ring-0"
              placeholder="Enter username"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
} 