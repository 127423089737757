import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function Navbar() {
  const { user, logout } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const teamId = params.get('teamId');

  return (
    <nav className="bg-white/70 backdrop-blur-lg border-b border-white/10 shadow-lg sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="bg-gradient-to-r from-teal-600 to-blue-600 bg-clip-text text-transparent font-bold text-xl">
              Product Sketch
            </Link>
          </div>

          {user && (
            <div className="flex items-center space-x-4">
              <Link 
                to={teamId ? `/settings?teamId=${teamId}` : '/settings'} 
                className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors"
              >
                Settings
              </Link>
              <button
                onClick={logout}
                className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
} 