import { 
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  query,
  where
} from 'firebase/firestore';
import { db } from './firebase';

export const taskService = {
  async createTask(task) {
    return await addDoc(collection(db, 'tasks'), {
      ...task,
      createdAt: new Date(),
      status: 'pending',
      teamId: task.teamId
    });
  },

  async updateTask(taskId, updates) {
    const taskRef = doc(db, 'tasks', taskId);
    return await updateDoc(taskRef, updates);
  },

  async deleteTask(taskId) {
    const taskRef = doc(db, 'tasks', taskId);
    return await deleteDoc(taskRef);
  },

  getTasksQuery(filters = {}) {
    const constraints = [];
    
    if (filters.teamId) {
      constraints.push(where('teamId', '==', filters.teamId));
    }
    
    return query(collection(db, 'tasks'), ...constraints);
  }
}; 