import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../services/firebase';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { userService } from '../services/userService';
import { doc, setDoc, updateDoc, increment, serverTimestamp, getDoc, arrayUnion, onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../services/firebase';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const refreshUserProfile = async (userId) => {
    console.log('Refreshing user profile:', userId);
    if (userId) {
      const profile = await userService.getUserProfile(userId);
      console.log('Fetched user profile:', profile);
      setUserProfile(profile);
    } else {
      setUserProfile(null);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user);
      setUser(user);
      if (user) {
        await refreshUserProfile(user.uid);
      } else {
        setUserProfile(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const joinTeam = async (userId, teamId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const teamRef = doc(db, 'teams', teamId);
      
      // Add user to team
      await setDoc(doc(db, 'teamMembers', `${teamId}_${userId}`), {
        userId: userId,
        teamId: teamId,
        role: 'member',
        joinedAt: serverTimestamp(),
        active: true
      });

      // Update user's teams array
      await updateDoc(userRef, {
        teams: arrayUnion(teamId)
      });

      // Update team members count and add user to members array
      await updateDoc(teamRef, {
        memberCount: increment(1),
        members: arrayUnion(userId)
      });

      // Refresh user profile
      await refreshUserProfile(userId);
    } catch (error) {
      console.error('Error joining team:', error);
      throw error;
    }
  };

  const login = async (email, password, teamId) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (teamId) {
        await joinTeam(userCredential.user.uid, teamId);
      }
      return userCredential;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  };

  const signup = async (email, password, teamId) => {
    try {
      console.log('Starting signup process with teamId:', teamId);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create initial user profile
      const userRef = doc(db, 'users', userCredential.user.uid);
      await setDoc(userRef, {
        email: userCredential.user.email,
        createdAt: serverTimestamp(),
        teams: teamId ? [teamId] : []
      });

      // If there's a teamId, create team membership immediately
      if (teamId) {
        console.log('Creating team membership for new user:', { userId: userCredential.user.uid, teamId });
        
        // Create team member document
        await setDoc(doc(db, 'teamMembers', `${teamId}_${userCredential.user.uid}`), {
          userId: userCredential.user.uid,
          teamId: teamId,
          role: 'member',
          joinedAt: serverTimestamp(),
          active: true
        });

        // Update team document
        const teamRef = doc(db, 'teams', teamId);
        const teamDoc = await getDoc(teamRef);
        
        if (teamDoc.exists()) {
          console.log('Updating team document with new member');
          await updateDoc(teamRef, {
            members: arrayUnion(userCredential.user.uid),
            memberCount: increment(1)
          });

          // Set this team as the user's current team
          await updateDoc(userRef, {
            currentTeamId: teamId,
            teams: arrayUnion(teamId)
          });
        } else {
          console.error('Team not found:', teamId);
        }
      }

      // Force refresh user profile and wait for it
      await refreshUserProfile(userCredential.user.uid);

      // Add a delay to ensure Firestore has propagated the changes
      await new Promise(resolve => setTimeout(resolve, 1000));

      console.log('Signup process completed successfully');
      return userCredential;
    } catch (error) {
      console.error('Error in signup:', error);
      throw error;
    }
  };

  const logout = () => {
    return signOut(auth);
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  // Add real-time listener for user profile updates
  useEffect(() => {
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setUserProfile({ id: doc.id, ...doc.data() });
      }
    });

    return () => unsubscribe();
  }, [user]);

  // Add real-time listener for team memberships with retry logic
  useEffect(() => {
    if (!user?.uid) return;

    console.log('Setting up team membership listener for:', user.uid);
    let retryCount = 0;
    const maxRetries = 3;

    const setupListener = () => {
      const teamMembersRef = collection(db, 'teamMembers');
      const q = query(teamMembersRef, where('userId', '==', user.uid));

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        console.log('Team memberships updated:', snapshot.size);
        if (snapshot.size > 0) {
          const userRef = doc(db, 'users', user.uid);
          const teamIds = snapshot.docs.map(doc => doc.data().teamId);
          await updateDoc(userRef, {
            teams: teamIds
          });
          await refreshUserProfile(user.uid);
        } else if (retryCount < maxRetries) {
          console.log(`No teams found, retrying... (${retryCount + 1}/${maxRetries})`);
          retryCount++;
          setTimeout(setupListener, 1000); // Retry after 1 second
        }
      }, error => {
        console.error('Team membership listener error:', error);
        if (retryCount < maxRetries) {
          retryCount++;
          setTimeout(setupListener, 1000);
        }
      });

      return unsubscribe;
    };

    const unsubscribe = setupListener();
    return () => unsubscribe();
  }, [user?.uid]);

  const value = {
    user,
    userProfile,
    refreshUserProfile,
    login,
    signup,
    logout,
    signInWithGoogle,
    joinTeam
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
} 