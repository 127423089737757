import { 
  doc, 
  setDoc, 
  getDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  updateDoc, 
  arrayUnion, 
  writeBatch, 
  increment, 
  serverTimestamp 
} from 'firebase/firestore';
import { db } from './firebase';
import { auth } from './firebase';

export const userService = {
  async createUserProfile(userId, data) {
    console.log('Creating user profile in Firestore:', { userId, data });
    try {
      const userRef = doc(db, 'users', userId);
      await setDoc(userRef, {
        email: data.email,
        username: data.username,
        teams: [],
        createdAt: new Date()
      });
      console.log('User profile created successfully');
      
      // Verify the document was created
      const verifyDoc = await getDoc(userRef);
      console.log('Verification of created document:', verifyDoc.data());
    } catch (error) {
      console.error('Error in createUserProfile:', error);
      throw error;
    }
  },

  async updateUserProfile(userId, data) {
    try {
      // Filter out any undefined values
      const cleanData = {};
      Object.keys(data).forEach(key => {
        if (data[key] !== undefined) {
          cleanData[key] = data[key];
        }
      });

      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, cleanData);
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  },

  async getUserProfile(userId) {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? { id: userSnap.id, ...userSnap.data() } : null;
  },

  async createTeam(name, ownerId) {
    try {
      // Create a new team document with default columns
      const teamsRef = collection(db, 'teams');
      const teamData = {
        name,
        ownerId,
        members: [ownerId],
        columns: [
          { id: 'todo', name: 'To Do' },
          { id: 'inprogress', name: 'In Progress' },
          { id: 'done', name: 'Done' }
        ],
        createdAt: serverTimestamp()
      };

      // Create the team
      const teamDoc = await addDoc(teamsRef, teamData);

      // Create team membership document for owner
      await setDoc(doc(db, 'teamMembers', `${teamDoc.id}_${ownerId}`), {
        userId: ownerId,
        teamId: teamDoc.id,
        role: 'owner',
        joinedAt: serverTimestamp(),
        active: true
      });

      // Return the new team data with its ID
      return {
        id: teamDoc.id,
        ...teamData
      };
    } catch (error) {
      console.error('Error creating team:', error);
      throw error;
    }
  },

  async updateTeamColumns(teamId, columns) {
    const teamRef = doc(db, 'teams', teamId);
    await updateDoc(teamRef, { columns });
  },

  async getTeamMembers(teamId) {
    const teamRef = doc(db, 'teams', teamId);
    const teamSnap = await getDoc(teamRef);
    if (!teamSnap.exists()) return [];

    const memberIds = teamSnap.data().members;
    const members = [];
    
    for (const memberId of memberIds) {
      const memberProfile = await this.getUserProfile(memberId);
      if (memberProfile) {
        members.push(memberProfile);
      }
    }
    
    return members;
  },

  async addTeamMember(teamId, userEmail) {
    console.log('Starting addTeamMember:', { teamId, userEmail });
    
    try {
      // First, find the user by email
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', userEmail));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        console.log('User not found, creating invitation');
        // Create invitation if user doesn't exist
        const invitationsRef = collection(db, 'invitations');
        await addDoc(invitationsRef, {
          email: userEmail,
          teamId: teamId,
          status: 'pending',
          createdAt: new Date(),
          invitedBy: auth.currentUser.uid
        });

        // Send invitation email
        const teamDoc = await getDoc(doc(db, 'teams', teamId));
        const inviterDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));

        const response = await fetch('/.netlify/functions/send-invitation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            invitation: { email: userEmail },
            team: { id: teamId, ...teamDoc.data() },
            inviter: inviterDoc.data()
          })
        });

        if (!response.ok) {
          throw new Error('Failed to send invitation email');
        }
      } else {
        // User exists, add them to the team directly
        const existingUser = querySnapshot.docs[0];
        const userId = existingUser.id;
        console.log('Found existing user:', userId);

        // Check if user is already a member
        const teamMemberRef = doc(db, 'teamMembers', `${teamId}_${userId}`);
        const teamMemberDoc = await getDoc(teamMemberRef);

        if (teamMemberDoc.exists()) {
          console.log('User is already a team member');
          return;
        }

        // Create team member document
        await setDoc(teamMemberRef, {
          userId: userId,
          teamId: teamId,
          role: 'member',
          joinedAt: new Date(),
          active: true
        });

        // Update user's teams array
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          teams: arrayUnion(teamId)
        });

        // Update team's members array
        const teamRef = doc(db, 'teams', teamId);
        await updateDoc(teamRef, {
          members: arrayUnion(userId),
          memberCount: increment(1)
        });

        console.log('Successfully added existing user to team');
      }
    } catch (error) {
      console.error('Error adding team member:', error);
      throw error;
    }
  },

  async getAllUsers() {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  },

  async getUserTeams(userId) {
    // First get the user's team IDs
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    if (!userSnap.exists()) return [];

    const userData = userSnap.data();
    const teamIds = userData.teams || [];

    // Then get the actual team data
    const teams = [];
    for (const teamId of teamIds) {
      const teamRef = doc(db, 'teams', teamId);
      const teamSnap = await getDoc(teamRef);
      if (teamSnap.exists()) {
        teams.push({ id: teamSnap.id, ...teamSnap.data() });
      }
    }

    return teams;
  },

  async deleteTeam(teamId) {
    // Get team data first
    const teamRef = doc(db, 'teams', teamId);
    const teamSnap = await getDoc(teamRef);
    if (!teamSnap.exists()) {
      throw new Error('Team not found');
    }

    const teamData = teamSnap.data();

    // Remove team from all members' teams array
    for (const memberId of teamData.members) {
      const userRef = doc(db, 'users', memberId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        await updateDoc(userRef, {
          teams: userData.teams.filter(id => id !== teamId)
        });
      }
    }

    // Delete all tasks associated with the team
    const tasksRef = collection(db, 'tasks');
    const q = query(tasksRef, where('teamId', '==', teamId));
    const taskSnap = await getDocs(q);
    
    const batch = writeBatch(db);
    taskSnap.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    // Delete the team document
    batch.delete(teamRef);
    
    await batch.commit();
  },

  async removeTeamMember(teamId, userId) {
    const teamRef = doc(db, 'teams', teamId);
    const userRef = doc(db, 'users', userId);

    const teamSnap = await getDoc(teamRef);
    const userSnap = await getDoc(userRef);

    if (!teamSnap.exists() || !userSnap.exists()) {
      throw new Error('Team or user not found');
    }

    const teamData = teamSnap.data();
    const userData = userSnap.data();

    // Remove user from team members
    await updateDoc(teamRef, {
      members: teamData.members.filter(id => id !== userId)
    });

    // Remove team from user's teams
    await updateDoc(userRef, {
      teams: userData.teams.filter(id => id !== teamId)
    });
  },

  // Add this method to check for pending invitations when a user signs up
  async checkPendingInvitations(userEmail) {
    const invitationsRef = collection(db, 'invitations');
    const q = query(invitationsRef, where('email', '==', userEmail), where('status', '==', 'pending'));
    const snapshot = await getDocs(q);

    const batch = writeBatch(db);
    
    for (const doc of snapshot.docs) {
      const invitation = doc.data();
      const teamRef = doc(db, 'teams', invitation.teamId);
      const teamSnap = await getDoc(teamRef);
      
      if (teamSnap.exists()) {
        const teamData = teamSnap.data();
        // Update team members
        batch.update(teamRef, {
          members: [...teamData.members, userEmail]
        });
        // Mark invitation as accepted
        batch.update(doc.ref, { status: 'accepted' });
      }
    }

    await batch.commit();
  },

  async getProjectMembers(projectId) {
    try {
      if (!projectId) {
        console.log('No projectId provided to getProjectMembers');
        return [];
      }

      console.log('Getting members for project:', projectId);

      const membersRef = collection(db, 'teamMembers');
      const q = query(membersRef, where('teamId', '==', projectId));
      
      const snapshot = await getDocs(q);
      console.log('Found team members:', snapshot.size);

      const memberPromises = snapshot.docs.map(async (doc) => {
        const memberData = doc.data();
        console.log('Processing member data:', memberData);
        
        if (!memberData.userId) {
          console.log('No userId found in member data');
          return null;
        }

        const userRef = doc(db, 'users', memberData.userId);
        const userDoc = await getDoc(userRef);
        
        if (!userDoc.exists()) {
          console.log('No user document found for userId:', memberData.userId);
          return null;
        }

        const userData = userDoc.data();
        console.log('Found user data:', userData);

        return {
          id: userDoc.id,
          email: userData.email || '',
          username: userData.username || '',
          role: memberData.role || 'member'
        };
      });

      const members = await Promise.all(memberPromises);
      const filteredMembers = members.filter(member => member !== null);
      console.log('Final members list for team:', projectId, filteredMembers);
      
      return filteredMembers;
    } catch (error) {
      console.error('Error getting project members:', error);
      return [];
    }
  }
}; 