import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [error, setError] = useState('');
  const [teamId, setTeamId] = useState(null);
  const { login, signup, signInWithGoogle } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get email and teamId from URL parameters
    const params = new URLSearchParams(location.search);
    const inviteEmail = params.get('email');
    const inviteTeamId = params.get('teamId');
    const isInvite = params.get('invite');
    
    if (inviteEmail && isInvite) {
      setEmail(inviteEmail);
      setTeamId(inviteTeamId);
      setIsSignup(true);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignup) {
        await signup(email, password, teamId);
        navigate('/', { replace: true });
      } else {
        await login(email, password);
        if (teamId) {
          navigate(`/?teamId=${teamId}`, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" style={{ backgroundColor: '#f0f0f0' }}>
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {isSignup ? 'Create an account' : 'Sign in to your account'}
          </h2>
          {email && isSignup && (
            <p className="mt-2 text-center text-sm text-gray-600">
              Sign up to join your team
            </p>
          )}
          {error && (
            <p className="mt-2 text-center text-sm text-red-600">
              {error}
            </p>
          )}
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="-space-y-px">
            <div>
              <input
                type="email"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:border-gray-700 focus:ring-0 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={!!location.search}
              />
            </div>
            <div>
              <input
                type="password"
                required
                className="appearance-none relative block w-full px-3 py-2 border border-gray-700 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:border-gray-700 focus:ring-0 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isSignup ? 'Sign up' : 'Sign in'}
            </button>
          </div>
        </form>

        <div className="mt-4">
          <button
            onClick={handleGoogleSignIn}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Sign in with Google
          </button>
        </div>
        
        {!location.search && ( // Only show toggle if not from invitation
          <div className="text-sm text-center mt-4">
            <button
              onClick={() => setIsSignup(!isSignup)}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {isSignup 
                ? 'Already have an account? Sign in' 
                : 'Need an account? Sign up'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
} 